import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BrowserRouter, Routes, Route, Router, Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const StyledMedia = styled.div`
    padding: 50px;
    text-align: center;
    background-color: #F4DFCA;
    min-height: 100vh;
`;

const MediaTitle = styled.h1`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 3em;
    color: #A67B5B;
`;

const MediaContent = styled.p`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 1.5em;
    color: #A67B5B;
    margin-top: 20px;
`;

const ArticleSection = styled.div`
    margin-top: 40px;
    background-color: #F4DFCA;
    padding: 20px;
    border-radius: 10px;
`;

const ArticleTitle = styled.h2`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 2em;
    color: #A67B5B;
`;

const ArticleContent = styled.p`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 1.2em;
    color: #A67B5B;
    margin-top: 10px;
    text-align: left;
    
    a {
        display: block;
        text-align: center;
        text-decoration: none;
        color: blue; // Set the color of the link to match the rest of the text
    }
`;

const ContactEmail = styled.p`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 1.2em;
    color: #A67B5B;
    margin-top: 40px;
`;

const StyledMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4DFCA;
    padding: 15px 0;
`;

const StyledMenuItem = styled(Link)`
    margin: 0 100px;
    text-decoration: none;
    font-family: 'Trebuchet MS', sans-serif;
    color: #A67B5B;
    font-size: 1.5em;
    &:hover {
        text-decoration: none;
    }
`;

export default function Media() {
    return (
        <StyledMedia>
            <StyledMenu>
                <StyledMenuItem to="/">Home</StyledMenuItem>
                <StyledMenuItem to="/about">About</StyledMenuItem>
                <StyledMenuItem to="/media">Media</StyledMenuItem>
            </StyledMenu>

            <MediaTitle>Media</MediaTitle>
            
            <ArticleSection>
                <ArticleTitle>Newsletter Feature</ArticleTitle>
                <ArticleContent>
                    <a
                        href="https://www.cs.umd.edu/article/2023/10/umd-undergrads-revolutionize-meal-planning-innovative-app"
                        style={{ textDecoration: 'none', textAlign: 'center' }}
                    >
                        agora. featured in UMD Computer Science Department's newsletter </a>
                </ArticleContent>
            </ArticleSection>
            
            <ContactEmail>
                Contact us at: <a href="mailto:agora.ai.app@gmail.com">agora.ai.app@gmail.com</a>
            </ContactEmail>
        </StyledMedia>
    );
}
