import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { BrowserRouter, Routes, Route, Router, Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const StyledAbout = styled.div`
    padding: 50px;
    text-align: center;
    background-color: #F4DFCA;
    min-height: 100vh;
`;

const AboutTitle = styled.h1`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 3em;
    color: #A67B5B;
`;

const AboutContent = styled.p`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 1.5em;
    color: #A67B5B;
    margin-top: 20px;
`;

const FounderSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
`;

const FounderImage = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;
`;

const FounderName = styled.h2`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 2em;
    color: #A67B5B;
`;

const FounderDescription = styled.p`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 1.2em;
    color: #A67B5B;
    margin-top: 10px;
    text-align: center;
    max-width: 400px;
`;

const StyledLink = styled.a`
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 1.2em;
    color: #A67B5B;
    margin: 10px 15px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const FoundersContainer = styled.div`
    display: flex;
    justify-content: space-evenly; // Distributes items evenly
`;

const StyledMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4DFCA;
    padding: 15px 0;
`;

const StyledMenuItem = styled(Link)`
    margin: 0 100px;
    text-decoration: none;
    font-family: 'Trebuchet MS', sans-serif;
    color: #A67B5B;
    font-size: 1.5em;
    &:hover {
        text-decoration: none;
    }
`;

export default function About() {
    return (
        <StyledAbout>
            <StyledMenu>
                <StyledMenuItem to="/">Home</StyledMenuItem>
                <StyledMenuItem to="/about">About</StyledMenuItem>
                <StyledMenuItem to="/media">Media</StyledMenuItem>
            </StyledMenu>

            <AboutTitle>About Us</AboutTitle>
            <FoundersContainer>
                <FounderSection>
                    {/* <FounderImage src="path_to_first_founder_image.jpg" alt="Co-Founder 1" /> */}
                    <FounderName>Pranav Dulepet</FounderName>
                    {/* <FounderDescription>
                        Co-Founder 1's description. A brief bio about the first co-founder and their contributions to the company.
                    </FounderDescription> */}
                    <div>
                        <StyledLink href="https://linkedin.com/in/pranavdulepet" target="_blank" rel="noopener noreferrer">
                            LinkedIn
                        </StyledLink>
                        <StyledLink href="http://pranavdulepet.github.io" target="_blank" rel="noopener noreferrer">
                            Personal Website
                        </StyledLink>
                    </div>
                </FounderSection>

                <FounderSection>
                    {/* <FounderImage src="path_to_second_founder_image.jpg" alt="Co-Founder 2" /> */}
                    <FounderName>Mukund Shankar</FounderName>
                    {/* <FounderDescription>
                        Co-Founder 2's description. A brief bio about the second co-founder and their contributions to the company.
                    </FounderDescription> */}
                    <div>
                        <StyledLink href="https://linkedin.com/in/mukundsh" target="_blank" rel="noopener noreferrer">
                            LinkedIn
                        </StyledLink>
                        <StyledLink href="https://mukundshankar-dev.github.io/" target="_blank" rel="noopener noreferrer">
                            Personal Website
                        </StyledLink>
                    </div>
                </FounderSection>
            </FoundersContainer>
        </StyledAbout>
    );
}