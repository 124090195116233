// LandingPage.js

import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from './Arnold-logos/4.png'; 
import ss1 from './Arnold-logos/iPhone-14-1.png'; 
import ss2 from './Arnold-logos/iPhone-14-2.png'; 
import ss3 from './Arnold-logos/iPhone-14-3.png'; 
import ss4 from './Arnold-logos/iPhone-14-3.png'; 
import ss5 from './Arnold-logos/iPhone-14-4.png'; 
import ss6 from './Arnold-logos/iPhone-14-5.png'; 
import ss7 from './Arnold-logos/iPhone-14-6.png'; 
import ss8 from './Arnold-logos/iPhone-14-7.png'; 
import ss9 from './Arnold-logos/iPhone-14-8.png'; 
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import twitterlogo from './Arnold-logos/twitterlogo.png';
import { BrowserRouter, Routes, Route, Router, Link} from 'react-router-dom';
import linkedinlogo from './Arnold-logos/linkedinlogo.png';
import appstorelogo from './Arnold-logos/appstorelogo.png';
import PrivacyPolicy from './Privacy';
import Media from './Media';
import About from './About';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const Spacer = styled.div`
  height: 100px;
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(15px);
  }
  60% {
    transform: translateY(7px);
  }
`;

const Arrow = styled.div`
  position: absolute;
  bottom: 30px;
  transform: translateX(-50%);
  animation: ${bounce} 2s infinite;
  color: #A67B5B;
  font-size: 3em;
`;

const StyledLanding = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 78vh;
  background-color: #F4DFCA; 
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const StyledLogo = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 768px) {
    width: 150px;
  }
`;

const StyledHeader = styled.h1`
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 5em;
  color: #A67B5B;

  @media (max-width: 768px) {
    font-size: 3em;
  }
`;

const StyledP = styled.p`
  font-family: 'Trebuchet MS', sans-serif;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const StyledParagraph = styled.p`
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 1.5em;
  color: #A67B5B; 
  line-height: 1.5;
  margin-top: 0.5em; // adjust as per your need
  margin-bottom: 0.5em; // adjust as per your need
`;

const StyledParagraphBig = styled.p`
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 3em;
  color: #A67B5B; 
  line-height: 1;
  font-weight: bold; // make the text bold
`;

const StyledForm = styled.form`
  font-family: 'Trebuchet MS', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
`;

const StyledInput = styled.input`
  font-family: 'Trebuchet MS', sans-serif;
  margin-bottom: 1em;
  padding: 0.5em;
  font-size: 1em;
  width: 100%;
  margin-top: 3em;
  border-radius: 15px; // adjust this value to get the roundness you desire
  border: none;
`;

const StyledSubmitButton = styled.button`
  font-family: 'Trebuchet MS', sans-serif;
  padding: 0.5em 1em;
  font-size: 1em;
  color: #FFFFFF;
  background-color: #C8AD7F;
  border: none;
  border-radius: 15px; // adjust this value to get the roundness you desire
`;


const StyledSmallText = styled.p`
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 0.7em;
  color: #A67B5B; 
  text-align: center;
  margin-top: 1em;
`;

const StyledText = styled.p`
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 0.8em;
  color: #A67B5B; 
`;

const StyledCarousel = styled(Carousel)`
  width: 800px;
  margin: 50px auto;

  .carousel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .slide {
    flex: 0 0 calc(33.33% - 16px); /* 3 images per slide with some spacing */
    margin-right: 16px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const StyledMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4DFCA;
    padding: 15px 0;
`;

const StyledMenuItem = styled(Link)`
    margin: 0 100px;
    text-decoration: none;
    font-family: 'Trebuchet MS', sans-serif;
    color: #A67B5B;
    font-size: 1.5em;
    &:hover {
        text-decoration: none;
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export default function LandingPage() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);
    
    const onSubmit = (data) => {
        console.log("EMAIL: ", data.email);
        (function () {
        emailjs.init("oX82JGprKPOAG37lZ");
        })();

        var templateParams = {
        reply_to: data.email,
        };

        emailjs.send('service_tplqbt4', 'template_pr71oji', templateParams)
        .then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
            console.log('FAILED...', error);
        });

        setFormSubmitted(true);
    };
    
    return (
      <MainContainer>
        <style>{'body { background-color: #F4DFCA; }'}</style>

          <StyledMenu>
              <StyledMenuItem to="/">Home</StyledMenuItem>
              <StyledMenuItem to="/about">About</StyledMenuItem>
              <StyledMenuItem to="/media">Media</StyledMenuItem>
          </StyledMenu>
  
          <ContentContainer>
              <StyledLanding>
                  <StyledLogo src={logo} alt="Logo" />
                  <StyledParagraphBig>The Marketplace for Healthy, Affordable, Personalized Meals</StyledParagraphBig>
                  <StyledParagraph>Discover the future of meal planning with an AI-powered app designed to make finding meals personal and effortless</StyledParagraph>
                  <Spacer> </Spacer>
                  <a href="https://apps.apple.com/us/app/agora/id6462011570?itsct=apps_box_badge&amp;itscg=30200">
                      <img 
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1695859200"
                          alt="Download on the App Store"
                          style={{
                            marginBottom: '10px',
                            // borderRadius: '13px',
                              width: '250px',
                              height: '83px'
                          }}
                      /> 
                  </a>

                  <a href="https://www.producthunt.com/posts/agora-efb1977d-db60-46de-8145-ed78e83705c5?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-agora&#0045;efb1977d&#0045;db60&#0045;46de&#0045;8145&#0045;ed78e83705c5">
                  <img
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=419362&theme=light"
                      alt="agora&#0046; - The&#0032;Marketplace&#0032;for&#0032;Healthy&#0044;&#0032;Affordable&#0044;&#0032;Personalized&#0032;Meals | Product Hunt"
                      width="250"
                      height="83"
                      style={{ marginBottom: '10px' }}
                  />
                  </a>

              </StyledLanding>
              {/* <Spacer> </Spacer> */}
          </ContentContainer>
  
          <footer style={{
              backgroundColor: '#A67B5B',
              padding: '5px',
              width: '100%'
          }}>
              <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '5px', fontFamily: 'Trebuchet MS' }}>
                  <Link to='/privacy' target='_blank'>
                      <a style={{ textDecoration: 'none', color: 'white' }}><b>Privacy Policy</b></a> 
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="mailto:agora.ai.app@gmail.com" target="_blank" style={{ textDecoration: 'none', color: 'white', fontFamily: 'Trebuchet MS' }}><b>Contact Us</b></a>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '5px' }}>
                  <br/>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                      <br/>
                      <a href="https://www.linkedin.com/company/91378172/" target="_blank"  style={{ marginRight: '0px' }}>
                          <img src={linkedinlogo} alt="LinkedIn" width="30" height="30"/>
                      </a>
                  </div>
              </div>
              <p style={{ 
                  color: 'white', 
                  fontSize: '10px', 
                  fontFamily: 'Trebuchet MS',
                  textAlign: 'center' 
              }}>
                  @2023 agora.
              </p>
          </footer>
      </MainContainer>
  );
}  